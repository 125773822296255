import FastClickComponent from "components/libs/fast-click";

//import * as _ from "components/helpers/easyJS";
//import { log } from "components/helpers/utils";

//import popup from "components/helpers/popup";


//import "components/definition/requestAnimationFrame";
//import "components/definition/matchesSelector";

//import scrollEvent from "components/helpers/scrollEvent";
//import scrollTop from "components/helpers/scrollTop";
//import ajax from "components/helpers/ajax";

export default () => {
  FastClickComponent();
  
  
	$('.js-photo-random-size').magnificPopup({
		delegate: 'a',
		type: 'image',
		tLoading: 'Loading image #%curr%...',
		mainClass: 'mfp-img-mobile',
		gallery: {
			enabled: true,
			navigateByImgClick: true,
			preload: [0,1] // Will preload 0 - before current, and 1 after the current image
		},
		image: {
			tError: '<a href="%url%">The image #%curr%</a> could not be loaded.',
			
		}
	});

  
  if($('.js-photo-random-size').length > 0){
    var slider_photos = tns({
      container: '.js-photo-random-size',
      controlsContainer: ".navs-random-size",
      autoplayTimeout: 5000,
      autoplayHoverPause:true,
      autoplayButtonOutput: false,
      nav: false,
      items: 3,
      slideBy: 1,
      gutter: 8,
      autoplay: true,
      autoWidth: true,
      loop: false,
      mouseDrag: false
    });
  }
  
  if($('.slider-4v').length > 0){ console.log(12);
    var slider_4v = tns({
      container: '.slider-4v',
      controlsContainer: ".navs",
      nav: false,
      items: 1,
      slideBy: 1,
      autoplay: false,
      loop: false,
      mouseDrag: true
    });
  }
  
  $(document).on('click','.js-open__page-content',function(e){
    e.preventDefault();
    
    let
      btn = $('.js-open__page-content'),
      content = $('.js-page-content')
    ;
    
    if(content.hasClass('is-view')){
      content.removeClass('is-view');
      btn.text(btn.data('view'));
    } else {
      content.addClass('is-view');
      btn.text(btn.data('unview'));
    }
  });
  
  $(document).on('click', '.js-image-source-view', function(e){
    e.preventDefault();
    
    $('.js-image-source-view').removeClass('is-view');
    $('.js-image-source').addClass('is-view');
    
  });
  
  $(document).on('click', '.js-menu-action', function(e){
    e.preventDefault();
    
    $('.js-menu-action').toggleClass('is-open');
    $('.js-menu').toggleClass('is-open');
    
  });
  
  var lastScrollTop = 0;
  
  document.addEventListener("scroll", function(){
     var st = window.pageYOffset || document.documentElement.scrollTop;
     
     if (st > lastScrollTop){
        
     } else {
        
     }
     lastScrollTop = st <= 0 ? 0 : st;
  }, false);
  
  (function(){
  
    function pageMenuCheckFixed(){
      if($('.js-topline-fixed').length){
        let 
          topline = $('.js-topline-fixed'),
          parentTopline = topline.parent()
        ;
        
        if($(document).scrollTop() >= parentTopline.offset().top){
        
          topline.addClass('is-fixed');
          parentTopline.css('height', topline.innerHeight());
          
        } else{
        
          topline.removeClass('is-fixed');
          parentTopline.css('height', '');
          
        }
      }
    }
    
    pageMenuCheckFixed();
    
    $(document).scroll(function(){
      pageMenuCheckFixed();
    });
  
  })();
};